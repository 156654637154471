<template>
  <main>
    <trac-loading v-if="isLoading" />
    <main v-show="userForm">
      <div>
        <trac-back-button> <h2 class="text-xs">Settings</h2></trac-back-button>
        <h1 class="font-medium mt-2">Employee Details</h1>
        <div class="mt-6 bg-white grid md:flex w-full white-gradient px-8 py-6">
          <div>
            <div class="w-64 bg-gray-400 h-56 rounded-tl-sm rounded-tr-sm">
              <img v-if="0" src="" class="w-full h-full object-cover" alt="" />
            </div>
            <div
              class="w-64 flex items-center justify-center py-3 cursor-pointer bg-gray-500 text-sm text-white"
            >
              Add Picture
            </div>
          </div>
          <trac-validation-observer slim v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(hideUserForm)"
              class="mt-6 md:mt-0 md:ml-10 md:pl-10 w-full md:border-l"
            >
              <trac-custom-header>
                <template slot="leading">Create</template>
                <template slot="trailing"> Users</template>
              </trac-custom-header>
              <div class="grid md:grid-cols-2 col-gap-10 row-gap-8">
                <trac-validation-provider
                  name="first name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="user.first_name"
                    placeholder="First Name"
                    ref="first name"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-validation-provider
                  name="last name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="user.last_name"
                    placeholder="Last Name"
                    ref="last name"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-validation-provider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model.trim="user.email"
                    ref="emails"
                    type="email"
                    placeholder="Email"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-validation-provider
                  name="phone number"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <trac-input
                  v-model="user.phone"
                  type="number"
                  placeholder="Phone Number"
                  ref="phone number"
                ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-validation-provider
                  name="password"
                  rules="required|min:8|atleastonenumber|atleastoneuppercase|atleastonelowercase|atleastonespecialcharacter"
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model.trim="user.password"
                    type="password"
                    specified="password"
                    placeholder="Create Password"
                    ref="password"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
              </div>
              <div class="flex justify-end items-end mt-10">
                <trac-button type="submit" variant="outline"> Next</trac-button>
              </div>
            </form>
          </trac-validation-observer>
        </div>
      </div>
    </main>
    <main v-show="!userForm">
      <div>
        <h2
          @click="userForm = true"
          class="text-xs cursor-pointer flex items-center"
        >
          <svg
            class="mr-2 w-3 mb-px h-3"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00195 1.00147L1.00195 7.00147L7.00195 13.0015"
              stroke="#013E9E"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Settings
        </h2>
        <h1 class="font-medium mt-2">Employee details</h1>
        <div class="mt-6 bg-white flex w-full white-gradient px-8 py-6">
          <div>
            <div class="w-64 bg-gray-400 h-56 rounded-tl-sm rounded-tr-sm">
              <img v-if="0" class="w-full h-full object-cover" alt="" />
            </div>
            <div
              class="w-64 flex items-center justify-center py-3 cursor-pointer bg-gray-500 text-sm text-white"
            >
              Add Picture
            </div>
          </div>
          <div class="ml-10 pl-10 w-full border-l">
            <trac-custom-header>
              <template slot="leading">Select</template>
              <template slot="trailing"> Roles</template>
            </trac-custom-header>
            <div class="flex items-center">
              <div class="text-sm">Select Store(S)</div>
              <div class="ml-6 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 text-xs">
                <div v-for="store in stores" :key="store.id">
                  <div class="mr-3 ml-4">
                    <div class="mt-4 whitespace-no-wrap overflow-hidden">
                      <trac-checkbox
                        class=""
                        @check="selectUserStore"
                        :value="store"
                        ><span class="text-sm pl-2 capitalize">{{
                          store.name
                        }}</span></trac-checkbox
                      >
                    </div>

                    <!-- <input
                      v-model="user.stores"
                      :value="store"
                      type="checkbox"
                      name=""
                      id=""
                    /> -->
                    <!-- <span class="ml-2 text-sm">{{ store.name }}</span> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-10">
              <div class="border relative p-4 rounded">
                <div
                  class="absolute top-0 -mt-2 left-0 ml-2 bg-white px-2 text-sm"
                >
                  Roles
                </div>
                <div class="mt-2 flex items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8.16267"
                      cy="8.16267"
                      r="7.16267"
                      stroke="#253B95"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.1164 17.1164L13.2217 13.2217"
                      stroke="#253B95"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <!-- <div
                    class="ml-3 text-primaryBlue w-56 text-sm uppercase font-medium"
                  >
                    <trac-input
                      v-model="search"
                      placeholder="Search Role"
                    ></trac-input>
                  </div> -->
                </div>
                <div class="mt-5 w-64">
                  <trac-dropdown
                    title="Select Role"
                    class="w-full"
                    :menuItems="filteredRoles"
                    v-model="user.role"
                  >
                    {{ filteredRoles.role }}</trac-dropdown
                  >
                </div>
                <!-- <div class="mt-4">
                  <trac-button variant="outline">New Role</trac-button>
                </div> -->
              </div>

              <div></div>
            </div>
            <div class="flex justify-end items-end mt-10">
              <trac-button @click.native="open">Save</trac-button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <trac-confirmation
      @close="confirmation = $event"
      :type="type"
      :active="confirmation"
    >
      {{ message }}
    </trac-confirmation>
  </main>
</template>

<script>
import {
  ADD_DATA_TO_DB,
  CONNECT_TO_DB,
  GET_DB_DATA,
  GET_OBJECT_STORE,
  SAVE_OBJECT_STORE,
} from "../../browser-db-config/indexedDB";
import { GET_USER_DATA } from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      isLoading: false,
      stores: [],
      roles: [],
      search: "",
      confirmation: null,
      type: null,
      message: null,
      userForm: true,
      user: {
        first_name: "",
        last_name: "",
        role: "",
        phone: "",
        email: "",
        password: "",
        stores: [],
      },
    };
  },
  computed: {
    filteredRoles() {
      return this.roles.filter((user) =>
        user.name.toLowerCase().includes(this.search.trim().toLowerCase())
      );
    },
  },
  methods: {
    selectUserStore(e) {
      if (e.checked) {
        this.user.stores.push(e.value);
      } else {
        this.user.stores = this.user.stores.filter(
          (store) => store.name.toLowerCase() !== e.value.name.toLowerCase()
        );
      }
    },
    hideUserForm() {
      this.userForm = false;
    },
    async open() {
      if (this.user.role === "") {
        this.message = "Please add a role";
        this.confirmation = true;
        this.type = false;
        return;
      }
      if (this.user.stores.length === 0) {
        this.message = "Please add one or more stores.";
        this.confirmation = true;
        this.type = false;
        return;
      }

      this.isLoading = true;

      let res = await this.$store.dispatch("CREATE_USER", this.user);

      if (res.status) {
        // const offlineEmployeesDB = await CONNECT_TO_DB(
        //   "offline_db_employees",
        //   1,
        //   "employees"
        // );
        // let offlineEmployees = await GET_OBJECT_STORE(
        //   'offline_db_employees',
        //   "employees"
        // );

        // offlineEmployees.data = offlineEmployees.data || [];

        // offlineEmployees.data.push(res.data);

        // await SAVE_OBJECT_STORE('offline_db_employees', "employees", offlineEmployees);

        this.message = `User created Successfully`;
        this.confirmation = true;
        this.type = true;
        this.$router.push({ name: "UserSettings" });
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "An error occured.",
        });
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    this.stores = GET_USER_DATA().stores.map((store) => ({
      name: store.name,
      id: store.id,
    }));
    let res = await this.$store.dispatch("FETCH_ALL_ROLES");
    let k = res.data ? res.data : [];
    let u = k.map((x) => ({ name: x.role }));
    this.roles = u;
  },
};
</script>

<style></style>
